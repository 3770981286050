import useTranslation from 'next-translate/useTranslation';
import { toast } from 'react-toastify';

export interface ToastProps {
  message?: string;
  type: 'success' | 'error';
}
const useToast = () => {
  const { t, lang } = useTranslation('common');

  const defaultMessage = {
    success: t('toast.request-succeed'),
    error: t('toast.something-went-wrong'),
  };
  const handleRenderToast = ({ message, type }: ToastProps) =>
    toast?.[type]?.(message || defaultMessage[type], {
      position: lang === 'ar' ? 'top-left' : 'top-right',
      hideProgressBar: true,
      autoClose: 3000,
    });
  return {
    handleRenderToast,
  };
};

export default useToast;
