import useTranslation from 'next-translate/useTranslation';
import {
  Description,
  ImageContainer,
  Section,
  StyledRow,
  Title,
  Container,
} from './YourUltimate.styled';
import Link from 'next/link';
import { LoginButton } from '@/components/Navbar/landingNavbar/Navbar.styled';
import { loginURL } from '@/components/Constants';

export default function YourUltimate() {
  const { t } = useTranslation('common');

  const text = t('landing.superchargeEfforts');
  const [firstWord, ...restOfText] = text.split(' ');
  const remainingText = restOfText.join(' ').split('\n');

  return (
    <Section>
      <Container>
        <StyledRow>
          <div className="first">
            <Title>
              <span className="first-word">{firstWord}</span>
              {remainingText.map((line, index) => (
                <span key={index} className="remaining-text">
                  {index === 0 ? ' ' : <br />}
                  {line}
                </span>
              ))}
            </Title>

            <Description>
              {t('landing.reach-is-management-platform')}
            </Description>
            <Link href={loginURL} passHref>
              <LoginButton isLanding>{t('landing.get-started')}</LoginButton>
            </Link>
          </div>
          <div className="second">
            <ImageContainer>
              <img
                src="/static/assets/images/landing/first.webp"
                alt="YourUltimate"
              />
            </ImageContainer>
          </div>
        </StyledRow>
      </Container>
    </Section>
  );
}
