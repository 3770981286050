import {
  NavBarLinks,
  NavbarSticky,
  NavbarWrapper,
  LoginButton,
  NavSwitchLang,
} from './Navbar.styled';
import { ReactSVG } from 'react-svg';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { loginURL } from '@/components/Constants';

export default function Navbar() {
  const { t, lang } = useTranslation('common');
  const { pathname, asPath, push } = useRouter();

  const handleChangeLanguage = () => {
    const locale = lang == 'en' ? 'ar' : 'en';
    push(pathname, asPath, { locale });
  };

  return (
    <NavbarSticky>
      <NavbarWrapper>
        <ReactSVG
          height={48}
          src="/static/assets/images/landing/brand-logo.svg"
        />
        <NavBarLinks>
          <Link href={loginURL} passHref>
            <LoginButton>{t('landing.login')}</LoginButton>
          </Link>
          <NavSwitchLang isLanding lang={lang} onClick={handleChangeLanguage}>
            {t('lang')}
          </NavSwitchLang>
        </NavBarLinks>
      </NavbarWrapper>
    </NavbarSticky>
  );
}
