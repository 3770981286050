import useTranslation from 'next-translate/useTranslation';
import {
  Description,
  ImageContainer,
  Section,
  StyledRow,
  Title,
  Container,
} from './GetStarted.styled';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import { GetStartedButton } from '../yourUltimate/YourUltimate.styled';
import { loginURL } from '@/components/Constants';

interface Props {
  title: string;
  desc: string;
  image: string;
  isReporting?: boolean;
}

export default function GetStarted({ title, desc, image, isReporting }: Props) {
  const { t } = useTranslation('common');

  return (
    <Section isReporting={isReporting}>
      <Container>
        <StyledRow>
          <div className="first">
            <Title>{title}</Title>
            <Description>{desc}</Description>

            <GetStartedButton>
              <Link href={loginURL} passHref>
                <span>{t('landing.get-started')}</span>
                <ReactSVG
                  src="/static/assets/images/landing/social-icons/arrow.svg"
                  title="Get Started"
                />
              </Link>
            </GetStartedButton>
          </div>
          <div className="second">
            <ImageContainer>
              <img src={image} alt={title} loading="lazy" />
            </ImageContainer>
          </div>
        </StyledRow>
      </Container>
    </Section>
  );
}
